@import "../../../node_modules/tailwindcss/dist/base.css";
@import "../../../node_modules/tailwindcss/dist/components.css";
@import "../../../node_modules/tailwindcss/dist/utilities.css";

@import "../../../node_modules/locomotive-scroll/src/locomotive-scroll";
@import "../../../node_modules/animate.css/animate.css";
@import "../../../node_modules/vegas/dist/vegas.min.css";
@import "../../../node_modules/aos/dist/aos.css";

// @import "../../../node_modules/hamburgers/_sass/hamburgers/base";

$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #fff !default;
// $hamburger-layer-color         : #000 !default;
// $hamburger-layer-border-radius : 4px !default;
// $hamburger-hover-opacity       : 0.7 !default;
// $hamburger-active-layer-color  : $hamburger-layer-color !default;
// $hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// // To use CSS filters as the hover effect instead of opacity,
// // set $hamburger-hover-use-filter as true and
// // change the value of $hamburger-hover-filter accordingly.
// $hamburger-hover-use-filter   : false !default;
// $hamburger-hover-filter       : opacity(50%) !default;
// $hamburger-active-hover-filter: $hamburger-hover-filter !default;

@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

//swiper
// @import "../../../node_modules/swiper/swiper-vars.scss";

// $themeColor: #fff !default;
// $primary: #fff;
// $primary: #FF9500;
// $primary: #FF9500;
$primary: #febf44;
$themeColor: #fff !default;
$colors: (
  "white": #ffffff,
  "black": #000000,
  // 'black': #000000,
) !default;

@import "../../../node_modules/swiper/swiper.scss";
@import "../../../node_modules/swiper/components/navigation/navigation.scss";
@import "../../../node_modules/swiper/components/pagination/pagination.scss";
// @import "../../../node_modules/swiper/swiper-bundle.css";
// .swiper-pagination-bullet-active{
// background: black;
// }

// .swiper-pagination{
//   margin-bottom: -30px;
// }

* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  margin: 0;
}

html {
  overflow-x: hidden;
}
// body {
//   position: relative
// }

*:focus {
  outline: none !important;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.bg-primary {
  background-color: $primary;
}
.bg-primary-500 {
  background-color: $primary;
}
.text-primary {
  color: $primary;
}

.gradient-top {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.gradient-bottom {
  background: rgb(33, 33, 33);
  background: linear-gradient(
    0deg,
    rgba(33, 33, 33, 1) 0%,
    rgba(33, 33, 33, 0) 100%
  );
}

aside {
  right: -50%;
  background: #18191c;
  .btn2 {
    background: rgba(35, 36, 38, 0.95);
    &:hover {
      background: rgba(53, 55, 58, 0.95);
    }
  }
  &.opened {
    right: 0;
  }
}

.parallax-window {
  min-height: 400px;
  background: transparent;
}

.hover-rounded {
  transition: all 0.5s ease;
  border-radius: 0px;
  -webkit-transition: all 0.5s ease;
  &:hover {
    border-radius: 20px;
  }
}

#form {
  transition: all;
  transition-duration: 300ms;
}
#form.busy {
  opacity: 0.3;
}
